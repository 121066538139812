import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({
    blogPost = false,
    description,
    meta = [],
    title,
    pathname
}) => {
    const { site } = useStaticQuery(query);

    const {
        author,
        defaultDescription,
        defaultTitle,
        lang,
        titleTemplate,
        twitter,
        siteUrl,
    } = site.siteMetadata;

    const seo = {
        description: description || defaultDescription,
        image: "https://res.cloudinary.com/dhgpgb0yw/image/upload/v1589160078/seo/dylanoleary_hkavwy.png",
        title: title || defaultTitle,
        url: `${siteUrl}${pathname}`
    }

    return (
        <Helmet
            title={seo.title}
            titleTemplate={titleTemplate}
        >
            <html lang={lang} />

            <meta name="author" content={author} />
            <meta name="description" content={seo.description} />

            <meta property="og:url" content={seo.url} />
            <meta property="og:description" content={seo.description} />
            {!blogPost && <meta property="og:image" content={seo.image} />}

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={twitter} />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            {!blogPost && <meta property="twitter:image" content={seo.image} />}

            {blogPost && meta}
        </Helmet>
    )
}

SEO.propTypes = {
    blogPost: PropTypes.bool,
    description: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    pathname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

const query = graphql`
    query {
        site {
            siteMetadata {
                author
                defaultDescription
                defaultTitle
                lang
                titleTemplate
                twitter
                siteUrl
            }
        }
    }
`;

export default SEO;